<template>
  <div class="shipments-page">
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Slots')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: 'head-of-fleet-order-queue-view', params: { type: $route.query.tab === 'Counter RTOs' ? 'delivery-orders' : 'returns', orderID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].trackingNumber" class="sm:pointer-events-none order-12">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p>{{ data[indextr].tracking_number }}</p>
            </div>
          </shipblu-td>

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td  class="order-4" :data="data[indextr].merchantName">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <span class="sm:text-base text-sm">{{ data[indextr].merchant.name }}</span>
          </shipblu-td>

          <shipblu-td  class="order-5" :data="data[indextr].customerName">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
            <span class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</span>
          </shipblu-td>

          <shipblu-td  class="order-6" :data="data[indextr].loaction">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.name) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td  class="order-7" :data="data[indextr].loaction">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Address')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].customer.address.line_1 }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ data[indextr].customer.address.line_2 }}</p>
          </shipblu-td>

          <shipblu-td  class="order-8" :data="data[indextr].slots">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Slots')}}</p>
            <span class="sm:text-base text-sm" v-for="packageItem in data[indextr].packages" :key="packageItem.index">{{ packageItem.package_location.aisle_sign ? packageItem.package_location.aisle_sign : packageItem.package_location.code }}</span>
          </shipblu-td>

          <shipblu-td  class="order-9" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages')}}</p>
            <span class="sm:text-base text-sm">{{ data[indextr].packages.length }}</span>
          </shipblu-td>

          <shipblu-td  class="order-10" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Package Size ')}}</p>
            <p class="sm:text-base text-sm" v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>

          <shipblu-td  class="order-11" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import MerchantsButton from './MerchantsButton.vue'
import ShipbluCursorPagination from '../../../layouts/components/ShipbluCursorPagination.vue'

export default {
  props: ['merchantFilter'],
  data () {
    return {
      common,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      tableLoader: false,
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      warehousesDic: {},
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
    selected (val) {
      this.$emit('returnsSelected', val)
    },
    '$route.query.tab' () {
      this.loadWarehouseOrders()
    },
    'merchantFilter' () {
      this.selected = []
      this.$router.push({
        query: {
          ...this.$route.query,
          merchant: this.merchantFilter.name
        }
      }).catch(() => {})
      this.loadWarehouseOrders()
    },
    '$route.params.warehouseID' () {
      this.selected = []
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      common.manageLoadWarehouses(this.formatWarehouses, this)
    }
  },
  methods: {
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadWarehouseOrders(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadWarehouseOrders()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.searchVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadWarehouseOrders()
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadWarehouseOrders()
    },
    loadWarehouseOrders (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&merchant=${this.merchantFilter && this.merchantFilter.id && this.merchantFilter.id !== 0 ? this.merchantFilter.id : ''}&${this.$route.query.tab === 'Counter RTOs' ? 'rto_requested=true' : ''}`
      sendRequest(true, false, this, `api/v1/${this.$route.query.tab === 'Counter RTOs' ? 'delivery-orders' : 'returns' }/?current_warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&status=in_transit&is_counter_dropoff=true${this.$route.params.warehouseID === 'SRT' ? '' : `&default_return_point_served_by=${this.warehousesDic[this.$route.params.warehouseID]}`}&${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    }
  },
  components: {
    MerchantsButton,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCursorPagination
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
export {common}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
